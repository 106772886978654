// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function stopwatchCreated(btn) {
  var timer = JSON.parse(localStorage.getItem('timer'));
  if (!timer) timer = {};
  if (timer[btn.attr('id')])
    setTimeout(function () {
      btn.trigger(click);
    }, 1000);
}
